body {
  margin: 0;
  font-family: "fakt pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button,
input {
  font-family: "fakt pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

button {
  cursor: pointer;
}

button:focus {
  outline: none;
}

hr {
  border: none;
  border-top: 1px solid #eee;
}

@font-face {
  font-family: "fakt pro";
  font-weight: normal;
  src: url(./fonts/FaktProNormal.otf);
  font-display: block;
}
@font-face {
  font-family: "fakt pro";
  font-weight: 600;
  src: url(./fonts/FaktProSemiBold.otf);
  font-display: block;
}
@font-face {
  font-family: "vampiro one";
  font-weight: normal;
  src: url(./fonts/VampiroOne-Regular.ttf);
  font-display: block;
}
