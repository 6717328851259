:root {
  --bg-black: #000;
  --bg-white: #fff;
  --box-shadow: 0 2px 16px rgb(0 0 0 / 5%);
}

.app {
  margin: 0 auto;
  max-width: 650px;
  padding-left: 16px;
  padding-right: 16px;
}
.app::before {
  background: #eaeaef;
  content: "";
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: -1;
}
.app__header {
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
  padding-bottom: 24px;
  z-index: 1;
  position: relative;
}
.app__logo {
  font-family: "vampiro one";
  font-size: 32px;
  letter-spacing: -2px;
  color: #8656ef; /*#2fd365*/
}
@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes slideInUp {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes subtlePulse {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: translateY(1);
  }
}
@keyframes rotating {
  to {
    transform: rotate(360deg);
  }
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.card {
  background: var(--bg-white);
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  padding: 20px 16px 16px;
  animation: slideIn 0.8s cubic-bezier(1, 0, 0, 1);
  margin-bottom: 24px;
}
.new-task {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 8px;
}
.new-task__button:first-child {
  background: #ece8f5;
  border-color: transparent;
  color: #8656ef;
}
.new-task__button {
  color: #fff;
  font-weight: 600;
  background: #8656ef;
  height: 38px;
  border: 1px solid;
  margin-left: 8px;
  padding: 3px 20px 1px;
  border-radius: 24px;
  font-size: 13px;
}
.new-task__button:active {
  animation: subtlePulse 0.3s cubic-bezier(0.5, 0, 0, 0.05);
}
.new-task__footer {
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
}
.new-task__text-input {
  border: none;
  margin-bottom: 10px;
  font-size: 14px;
  padding-left: 0;
  width: 100%;
}
.new-task__text-input:first-child {
  font-size: 16px;
  margin-bottom: 16px;
}
.new-task__text-input:focus {
  outline: none;
}
.new-task__text-input::placeholder,
.new-task__date-input::placeholder {
  color: #aaa;
}
.new-task__date-input {
  background: transparent;
  border: none;
  color: currentColor;
  font-size: 14px;
  padding: 7px 0 8px;
  border-radius: 20px;
  position: relative;
  max-width: 140px;
}
.new-task__date-input:focus {
  outline: none;
}
.new-task__date-input::before {
  content: "";
  background: url("../../svg/icon--calendar.svg") no-repeat transparent;
  background-size: contain;
  display: block;
  position: absolute;
  right: 5px;
  margin-top: -2px;
  height: 11px;
  width: 15px;
}
.new-task__date-input::-webkit-inner-spin-button,
.new-task__date-input::-webkit-calendar-picker-indicator {
  /* display: none; */
  /* -webkit-appearance: none; */
  opacity: 0;
}
.todo-item {
  background: #fff;
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  padding: 16px 16px 16px;
  justify-content: flex-start;
  display: flex;
  align-items: center;
  grid-column-gap: 8px;
  margin-bottom: 8px;
  text-decoration: none;
  color: currentColor;
  align-items: flex-start;
  -webkit-tap-highlight-color: transparent;
  animation: slideIn 0.8s cubic-bezier(1, 0, 0, 1);
}
.todo-item p {
  margin-top: 2px;
  margin-bottom: 0;
  line-height: 20px;
}
.todo-item button {
  background: transparent;
  border: none;
  padding-left: 16px;
  padding-right: 6px;
}
.todo-item button img {
  position: relative;
  top: 3px;
}
.todo-item button + div.more {
  background: var(--bg-white);
  box-shadow: var(--box-shadow);
  border-radius: 6px;
  font-size: 14px;
  margin: 4px 0 0 -38px;
  position: absolute;
  /* pointer-events: none; */
  padding: 8px 0 4px 0;
  visibility: hidden;
  width: auto;
  z-index: 1;
}
.todo-item .more button {
  color: currentColor;
  font-size: inherit;
  padding: 8px 24px 8px 16px;
  pointer-events: auto;
  text-align: left;
  width: 100%;
}
.todo-item button + div.more.show-more {
  visibility: visible;
}
.todo-item div:nth-child(2) {
  width: -webkit-fill-available;
}
.collapsed {
  display: none;
  padding-top: 8px;
  animation: slideInUp 0.4s cubic-bezier(0.5, 0, 0, 0.5);
}
.collapsed img {
  margin-right: 8px;
}
.expand {
  display: block !important;
}
.collapsed div:first-child small {
  line-height: 30px;
}
.collapsed div small {
  font-size: 14px;
}
.footer__text {
  color: rgba(0 0 0 / 30%);
  font-size: 13px;
  padding-top: 24px;
  padding-bottom: 16px;
  text-align: center;
}
p.title {
  color: #fff;
  display: inline-block;
  margin-top: 24px;
  margin-bottom: 16px;
  position: relative;
  font-weight: 600;
  font-size: 12px;
  z-index: 0;
  background: var(--bg-black);
  padding: 7px 12px 5px;
  border-radius: 22px;
}
.emptystate {
  text-align: center;
}
.emptystate svg {
  height: 100px;
  margin-top: 25px;
}
.emptystate .sun {
  animation: rotating 20s linear infinite;
  transform-origin: center;
  transform: rotate(0deg);
  transform-box: fill-box;
}

input[type="checkbox"]::before {
  background: #eaeaef;
  border-radius: 3px;
  content: "";
  display: block;
  height: 14px;
  visibility: visible;
  position: absolute;
  width: 14px;
}
input[type="checkbox"] {
  margin-right: 6px;
  visibility: hidden;
  position: relative;
}
input[type="checkbox"]:disabled::before {
  top: -2px;
}
input[type="checkbox"]:not(:disabled)::before {
  background: transparent;
  border: 1px solid rgba(0 0 0 / 12%);
  border-radius: 3px;
  cursor: pointer;
  content: "";
  display: block;
  height: 14px;
  visibility: visible;
  width: 14px;
}
input[type="checkbox"]:checked:not(:disabled)::before {
  background: rgb(82 169 76);
  border-color: rgb(82 169 76);
}
input[type="checkbox"]:checked:not(:disabled)::after {
  content: "";
  background: url("../../svg/icon--checkmark.svg") no-repeat transparent;
  cursor: pointer;
  display: block;
  height: 6px;
  position: absolute;
  width: 14px;
  visibility: visible;
  background-size: contain;
  top: 5px;
  left: 4px;
}
.modal {
  display: none;
}
.modal > div {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
  padding-top: 150px;
}
.modal-overlay {
  background: rgb(14 9 25 / 30%);
  height: 100vh;
  backdrop-filter: blur(1px);
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
}
.show-modal {
  display: block !important;
  padding-left: 16px;
  padding-right: 16px;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  width: -webkit-fill-available;
  z-index: 1;
}
.modal-form {
  background: var(--bg-white);
  position: relative;
  width: -webkit-fill-available;
  max-width: 620px;
  z-index: 10;
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  padding: 32px 32px 24px;
  animation: slideIn 0.8s cubic-bezier(1, 0, 0, 1);
}
.modal-form__edit {
  max-width: 340px;
  text-align: center;
}
.modal-form__edit p {
  margin-top: 0;
}
.modal-form__edit div {
  margin: 0 auto;
}
h1 {
  font-size: 18px;
  margin: 0 0 12px;
}

@media (max-width: 450px) {
  .new-task__button {
    font-size: 13.5px;
  }
  .new-task__date-input::before {
    content: unset;
  }
  .todo-item p {
    line-height: 24px;
  }
  .modal-form {
    padding: 24px 16px 16px;
  }
}
